<template>
  <v-footer dark class="pa-0 ma-0 d-flex flex-column">
    <div class="pa-0 ma-0" style="width:100%">
      <v-card fluid flat tile class="bg-grey-darken-3 text-white rounded-0">
        <v-card-text class="text-white pa-5">
          <v-container class="pa-5">
            <v-row style="max-width: 1250px; min-height: 150px" class="mx-auto footer">
              <v-col class="col-xs-12 col-md-6 col-lg-6">
                <h5 class="grey-text">AIT Home Delivery UK Ltd</h5>
                <p class="grey-text">
                  Customer care is at the heart of our business and our
                  dedicated team are here to keep you informed throughout the
                  delivery process. We focus on delivering the highest possible
                  quality of service to our customers.
                </p>
              </v-col>
              <v-col class="col-xs-12 col-md-3 col-lg-3">
                <h5 class="grey-text">Customer Services</h5>
                <p class="grey-text" style="display: none">
                  <i class="fa fa-phone fa-fw" style="text-align: left"></i
                  >{{ depotCustomerServicesPhone }}
                </p>
                <p class="grey-text">
                  <i class="fa fa-envelope fa-fw" style="text-align: left"></i
                  ><i class="fa fa-phone fa-fw" style="text-align: left"></i
                  ><a :href="`http://www.${domainHere}/contact/`"
                    >Customer Services</a
                  >
                </p>
              </v-col>
              <v-col class="col-xs-12 col-md-3 col-lg-3">
                <h5 class="grey-text">Address</h5>
                <p class="grey-text">
                  <i class="fa fa-map-marker fa-fw" style="text-align: left"></i
                  >AIT Home Delivery UK Ltd<br />Lodge Farm Industrial
                  Estate<br />Northampton<br />NN5 7US
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div class="pa-0 ma-0" style="width:100%">
      <v-card fluid flat tile class="bg-grey-darken-4 text-white rounded-0">
        <v-card-text>
          <v-container class="pa-0">
            <v-row
              style="max-width: 1250px; height: 40px;"
              class="mx-auto sub-footer"
              justify="space-between"
              align="center"
            >
              <span>©{{ footerYear }} AIT Home Delivery UK Ltd</span>
              <a class="grey-text" :href="`http://www.${domainHere}/`"
                >www.{{domainHere}}</a
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </v-footer>
</template>

<script>
import moment from "moment";

export default {
  name: "FooterBar",

  data: () => ({
    depotCustomerServicesPhone: "",
    domainHere: process.env.VUE_APP_DOMAIN,
    footerYear: moment(new Date()).format("YYYY"),
  }),
};
</script>

<style scoped>
h5{
  font-size: 1.64rem;
  line-height: 1.804rem;
  margin: 0.82rem 0 0.656rem 0;
  font-weight: 400;
  color: rgb(158, 158, 158);

}
.footer{
  color: rgb(158, 158, 158);
  font-size: 15px;
  font-weight: 400;
}
.footer a {
    color: #BDBDBD;
}
.footer a:hover {
    color: #9e9e9e;
}

.sub-footer {
	color: rgb(158, 158, 158);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 50px;
}

.sub-footer a {
    color: #BDBDBD;
    text-decoration: none;
}
.sub-footer a:hover {
    color: #9e9e9e;
}
</style>
