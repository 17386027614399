import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';

import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";

import 'vuetify/styles';
import '../assets/css/animations.css';
import '../assets/css/main.css';
import { createVuetify } from 'vuetify';

import {
    VApp,
    VMain,
    VAppBar,
    VFooter,
    VContainer,
    VRow,
    VCol,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VCardText,
    VCardActions,
    VBtn,
    VImg,
    VTextField,
    VSpacer,
    VIcon,
    VSnackbar,
    VOverlay,
    VAlert,
    VDivider,
    VExpandTransition,
    VToolbar,
    VToolbarTitle,
    VToolbarItems,
    VSelect,
    VTextarea,
    VListItem,
    VDialog,
    VCheckbox,
    VTooltip,
    VTable,
    VForm,
    VMenu,
    VRadio,
    VRadioGroup,
    VDatePicker
} from 'vuetify/components'

const vuetify = createVuetify({
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
          mdi,
          fa,
        },
      },
    components: {
        VApp,
        VMain,
        VAppBar,
        VFooter,
        VContainer,
        VRow,
        VCol,
        VCard,
        VCardTitle,
        VCardSubtitle,
        VCardText,
        VCardActions,
        VBtn,
        VImg,
        VTextField,
        VSpacer,
        VIcon,
        VSnackbar,
        VOverlay,
        VAlert,
        VDivider,
        VExpandTransition,
        VToolbar,
        VToolbarTitle,
        VToolbarItems,
        VSelect,
        VTextarea,
        VListItem,
        VDialog,
        VCheckbox,
        VTooltip,
        VTable,
        VForm,
        VMenu,
        VRadio,
        VRadioGroup,
        VDatePicker
    }
});

export default vuetify;
