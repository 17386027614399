<template>
  <v-container class="pa-0">
    <!-- Image  -->
    <v-card tile height="100%">
      <v-img
        :src="require('@/assets/images/' + image)"
        class="align-end"
        height="100%"
      >
        <v-card-text>
          <v-img v-if="logo" class="logo" :src="logo"> </v-img>
          <div class="title-bg">
            <div class="title-icon">
              <v-icon class="text-white image-icon" size="85" :icon="icon"></v-icon>
            </div>
            <div class="title-text">
              <span class="image-title-sub">{{type}}</span>
              <span class="image-title">{{title}}</span>
            </div>
          </div>
        </v-card-text>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ImageCard",
  props: ["id", "title", "icon", "image", "height", "type", "logo"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.logo {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 4.5em;
  width: 110px;
  opacity: 1;
  margin-top: 20px;
  margin-right: 20px;
}
.image-title,
.image-title-sub {
  color: #fff;
  position: relative;
  font-weight: 100;
  text-transform: capitalize;
  line-height: normal;
  float: left;
}
.image-title {
  font-size: 4.5rem;
  clear: both;
  margin: -15px 0px -10px -5px;
}
.image-title-sub {
  font-size: 2rem;
}
.image-icon {
	color: #fff;
	font-size: 85px;
	position: relative;
  bottom: 0px;
	/*left: 25px;*/
	/*bottom: 25px;*/
}
.title-bg {
	display: block;
	background-color: rgba(0,0,0,0.20);
	position: absolute;
	bottom: 15px;
	border-radius: 3px;
	padding: 20px;
}
.title-icon {
	display: inline-block;
	float: left;
}
.title-text {
	display: inline-block;
	padding-left: 15px;
}

@media only screen and (max-width: 600px) {
	.image-title {
    font-size: 2.5rem;
  }
  .image-title-sub {
    font-size: 2rem;
  }
  .image-icon {
    font-size: 2em;
    size: 24px;
  }
}
</style>

