<template>
  <v-app>
    <NavBar />

    <v-main class="main-content">
      <router-view/>
    </v-main>
    
    <FooterBar />
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'

export default {
  name: 'App',
  components: { NavBar, FooterBar },
  data: () => ({
    //
  }),

    
};
</script>

<style>

.main-content {
  margin-top: 30px;
  background-color: #E0E0E0;
  min-height: calc(100vh - 290px);
}
</style>