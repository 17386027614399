<template>
  <v-app-bar
    app      
    light
    color="#041E3D"
    class="animated fadeInDownBig"
    id="header"
  >
    <v-container class="pa-5 justify-space-around">
      <v-row style="max-width: 1250px" class="mx-auto">
        <v-col class="col-12 col-md-8">
          <v-img
            alt="AIT Home Delivery UK Ltd"
            class="shrink"
            contain
            src="@/assets/images/ait-logo-white.png"
            max-height="50"
            max-width="150"
          />
        </v-col>        
      </v-row>
    </v-container>
  </v-app-bar>
    
</template>

<script>
export default {
    
}
</script>
