<template>
  <v-container class="pa-5 justify-space-around">
    <v-row style="max-width: 1250px" class="mx-auto">
      <v-col class="col-xs-6 col-md-4 col-lg-4">
        <v-card tile height="100%">
          <v-card-title>Enter the tracking code:</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="trackingCode"
              append-icon="mdi-magnify"
              clearable
              label="#Tracking Code"
              outlined
              dense
              color="grey"
              @click:append="sendCode"
              @click:clear="clearCode"
              @keyup.enter="sendCode"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12 col-md-8">
        <image-card
          title="Search"
          icon="fa:fas fa-search"
          image="booked-image.jpg"
          height="357px"
          type="Order"
          logo=""
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImageCard from "../components/ImageCard";

export default {
  name: "SearchPage",
  components: {
    ImageCard,
  },
  data: () => ({
    trackingCode: "",
  }),
  methods: {
    sendCode() {
      if (this.trackingCode.length > 0) {
        let path = this.trackingCode;
        this.clearCode();
        this.$router.push(path);
      }
    },
    clearCode() {
      this.trackingCode = "";
    },
  },
};
</script>
<style scoped>
</style>

