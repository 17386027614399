import moment from 'moment';

export default function formatDate (input,format='DD/MM/YYYY') {
    if (!input) return '';

    var timestamp = Date.parse(input);
    if (isNaN(timestamp)) {    
    return input;
    }

    return moment(new Date(input)).format(format);
}