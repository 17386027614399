import {createRouter, createWebHistory} from 'vue-router'
import SearchView from '../views/SearchView.vue'

const routes = [
  {
    path: '/',
    name: 'search',
    component: SearchView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/upgrade-success/:trackingCode',
    name: 'stripeUpgrade',
    component: () => import('../views/StripeView.vue'),
    props: {stripeCode: 'upgrade-success'}
  },
  {
    path: '/upgrade-canceled/:trackingCode',
    name: 'stripeCanceled',
    component: () => import('../views/StripeView.vue'),
    props: {stripeCode: 'upgrade-canceled'}
  },
  {
    path: '/upgrade-failure/:trackingCode',
    name: 'stripeFailure',
    component: () => import('../views/StripeView.vue'),
    props: {stripeCode: 'upgrade-failure'}
  },
  {
    path: '/upgrade-duplicate/:trackingCode',
    name: 'stripeDuplicate',
    component: () => import('../views/StripeView.vue'),
    props: {stripeCode: 'upgrade-duplicate'}
  },
  {
    path: '/:clientId/:conNo',
    name: 'client',
    component: () => import('../views/ResultView.vue')
  },
  {
    path: '/:trackingCode',
    name: 'result',
    component: () => import('../views/ResultView.vue')
  },
  
  
]

const routeHistory = createWebHistory(
  process.env.BASE_URL
);

const router = new createRouter({
  history: routeHistory,
  routes
})

export default router
