<template>
  <search-page />
</template>

<script>
  import SearchPage from '../components/SearchPage'

  export default {
    name: 'SearchPageView',

    components: {
      SearchPage,
    },
  }
</script>
